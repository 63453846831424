import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Input } from "reactstrap";
import styled from "styled-components";
import { useGlobalMutationContext } from "../../globalMutation";
import { useSocket } from "../../socket";
import { EntityInfo } from "../Feed/hooks";

const Wrapper = styled.div`
  padding: 20px;
`;

export function Chat() {
  const socket = useSocket();
  const { subscribe, unsubscribe } = useGlobalMutationContext();
  const [scope, setScope] = useState("global");
  const [message, setMessage] = useState("");
  const [dmTargets, setDmTargets] = useState<EntityInfo[]>([]);

  const focusDMCallback = useCallback(
    ({ entity }: { entity: EntityInfo }) => {
      setDmTargets((val) =>
        val.some((i) => i.uid === entity.uid) ? val : [...val, entity]
      );
      setScope(`dm:${entity.uid}`);
    },
    [setScope, setDmTargets]
  );
  useEffect(() => {
    subscribe("focusChatDM", focusDMCallback);
    return () => unsubscribe("focusChatDM", focusDMCallback);
  }, [subscribe, unsubscribe]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    socket.emit("chat", { scope, message });
    setMessage("");
  };

  return (
    <Wrapper>
      <Form onSubmit={onSubmit} className="d-flex">
        <Input
          type="select"
          name="scope"
          value={scope}
          className="mr-3"
          style={{ width: 150 }}
          onChange={(e) => setScope(e.target.value)}
        >
          <option value="local">This Room</option>
          <option value="global">Global</option>
          {dmTargets.map((target) => (
            <option key={target.uid} value={`dm:${target.uid}`}>
              {target.properties.name}
            </option>
          ))}
        </Input>
        <Input
          type="text"
          name="message"
          value={message}
          className="flex-1 mr-3"
          autoComplete="off"
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button color="primary" type="submit" style={{ flexShrink: 0 }}>
          Send Message
        </Button>
      </Form>
    </Wrapper>
  );
}
