export class Stack<T> {
  private values: T[];

  constructor() {
    this.values = [];
  }

  push(value: T) {
    this.values.push(value);
  }

  pop() {
    return this.values.pop();
  }

  peek() {
    return this.values[this.values.length - 1];
  }
}
