import React, { useEffect, useState } from "react";
import { LineUIButton, LineUIButtons } from "../../events";
import { useSocket } from "../../socket";
import { InlineButton } from "./InlineButton";

interface Props {
  ui: LineUIButtons;
}

export function InlineButtons({ ui }: Props) {
  const [disabled, setDisabled] = useState(false);
  const [selected, setSelected] = useState<LineUIButton | undefined>();
  const socket = useSocket();
  const click = (button: LineUIButton) => {
    socket.emit("activateInlineUi", { uid: ui.uid, value: button.text });
    setDisabled(true);
    setSelected(button);
  };
  useEffect(() => {
    if (!disabled) {
      const timeoutId = setTimeout(() => {
        setDisabled(true);
      }, ui.timeout * 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [ui.timeout, disabled, setDisabled]);
  return (
    <div className="d-flex align-items-center">
      {ui.buttons.map((button) => (
        <InlineButton
          key={button.uid}
          onClick={() => click(button)}
          button={button}
          selected={selected === button}
          disabled={disabled}
        />
      ))}
    </div>
  );
}
