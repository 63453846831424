import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { NotificationPreferences } from "./NotificationPreferences";
import { useActivePlayerContext } from "../../player";

const Container = styled.div`
  padding: 5px 10px 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #131313;
`;

export function TopBar() {
  const player = useActivePlayerContext();
  return (
    <Container>
      <div>
        {player && (
          <>
            <FontAwesomeIcon icon={faUser} className="mr-3" />
            <span>
              {player.properties.name} {/*<a href="#">(log out)</a>*/}
            </span>
          </>
        )}
      </div>
      <NotificationPreferences />
    </Container>
  );
}
