import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Alert, Button, Form, FormGroup, Input } from "reactstrap";
import { Wrapper } from "../components/layout";

interface LoginFields {
  username: string;
  password: string;
}

function Login() {
  const { register, handleSubmit } = useForm<LoginFields>();
  const history = useHistory();
  const [error, setError] = useState("");
  const submit = async (fields: LoginFields) => {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/login`, {
      method: "POST",
      body: JSON.stringify(fields),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    if (res.ok) {
      history.push("/play");
    } else if (res.status === 401) {
      setError("Incorrect username or password.");
    } else {
      setError("An unknown error occurred. Please try again.");
    }
  };
  return (
    <Wrapper>
      <h3>Log in</h3>
      <Form onSubmit={handleSubmit(submit)}>
        {!!error && <Alert color="danger">{error}</Alert>}
        <FormGroup>
          <Input
            type="text"
            innerRef={register}
            name="username"
            placeholder="Username"
          />
        </FormGroup>
        <FormGroup>
          <Input
            type="password"
            innerRef={register}
            name="password"
            placeholder="Password"
          />
        </FormGroup>
        <Button color="primary">Submit</Button>
        <p className="mt-4">
          Don't have an account yet? <a href="/register">Sign up</a>
        </p>
        <p className="mt-3">
          Forgot your password? <a href="/forgotpassword">Reset password</a>
        </p>
      </Form>
    </Wrapper>
  );
}

export default Login;
