import React, { useState, useCallback } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeUp,
  faVolumeMute,
  faVolumeDown,
} from "@fortawesome/free-solid-svg-icons";
import { useSocketListener } from "../../hooks";
import { LineEvent } from "../../events";

type NotificationPreference = "all" | "chat" | "none";

const NOTIFICATION_PREFERENCE_KEY = "notification-preference";

export function NotificationPreferences() {
  const [notificationPreference, setNotificationPreference] = useState<
    NotificationPreference
  >(
    (localStorage.getItem(
      NOTIFICATION_PREFERENCE_KEY
    ) as NotificationPreference) ?? "chat"
  );
  const [soundDropdownOpen, setSoundDropdownOpen] = useState(false);

  useSocketListener(
    "line",
    useCallback(
      (data: LineEvent) => {
        const shouldNotify =
          document.hidden &&
          (notificationPreference === "all" ||
            (notificationPreference === "chat" && data.flags.chat));
        if (shouldNotify) {
          const audio = new Audio("/audio/notification.flac");
          audio.play();
        }
      },
      [notificationPreference]
    )
  );

  const changePreference = (preference: NotificationPreference) => {
    setNotificationPreference(preference);
    localStorage.setItem(NOTIFICATION_PREFERENCE_KEY, preference);
  };

  const icon = (() => {
    switch (notificationPreference) {
      case "all":
        return faVolumeUp;
      case "chat":
        return faVolumeDown;
      case "none":
        return faVolumeMute;
    }
  })();

  return (
    <Dropdown
      isOpen={soundDropdownOpen}
      toggle={() => setSoundDropdownOpen(val => !val)}
      direction="left"
      size="sm"
    >
      <DropdownToggle>
        <FontAwesomeIcon icon={icon} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => changePreference("all")}>
          Alert for all messages while tabbed out
        </DropdownItem>
        <DropdownItem onClick={() => changePreference("chat")}>
          Alert for chat only
        </DropdownItem>
        <DropdownItem onClick={() => changePreference("none")}>
          Disable alerts
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
