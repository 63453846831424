import React from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Input, Button } from "reactstrap";

export interface CreateThingParams {
  name: string;
  description: string;
}

interface Props {
  onCreateThing: (room: CreateThingParams) => void;
}

const CreateThingForm = ({ onCreateThing }: Props) => {
  const { register, handleSubmit } = useForm<CreateThingParams>();
  return (
    <Form onSubmit={handleSubmit(onCreateThing)}>
      <FormGroup>
        <Input placeholder="Name" name="name" innerRef={register} />
      </FormGroup>
      <FormGroup>
        <Input
          placeholder="Description"
          name="description"
          innerRef={register}
          type="textarea"
        />
      </FormGroup>
      <Button type="submit" color="primary">
        Create
      </Button>
    </Form>
  );
};

export default CreateThingForm;
