import React, { useState } from "react";
import { Alert, Button, Form, Input } from "reactstrap";
import { Wrapper } from "../components/layout";
import { request } from "../httpClient";

export function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [sent, setSent] = useState(false);
  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSent(false);
    setNotFound(false);
    const res = await request(`/forgotpassword`, {
      method: "POST",
      body: { email },
    });
    if (res.status === 404) setNotFound(true);
    else if (res.status === 200) setSent(true);
  };
  return (
    <Wrapper>
      <h3 className="mb-4">Reset Password</h3>
      <Form onSubmit={submit}>
        {notFound && (
          <Alert color="danger">
            We couldn't find an account with that email.
          </Alert>
        )}
        {sent && (
          <Alert color="success">
            We've sent an email to reset your password.
          </Alert>
        )}
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-3 mt-4"
        />
        <Button color="primary" onClick={submit}>
          Submit
        </Button>
      </Form>
    </Wrapper>
  );
}
