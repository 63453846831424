import React from "react";
import styled from "styled-components";
import { LineUIButton } from "../../events";

interface Props {
  button: LineUIButton;
  onClick: () => void;
  disabled?: boolean;
  selected?: boolean;
}

export function InlineButton({ button, onClick, disabled }: Props) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      style={{
        color: button.textColor,
        backgroundColor: button.backgroundColor,
        borderColor: button.borderColor,
      }}
    >
      {button.text}
    </Button>
  );
}

const Button = styled.button`
  transition: filter 0.1s ease-out;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding: 6px 12px;
  &:hover:not(:disabled) {
    filter: brightness(85%);
  }
  &:disabled {
    filter: brightness(70%);
  }
`;
