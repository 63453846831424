import React, { useState, useRef } from "react";
import { Tooltip, TooltipProps } from "reactstrap";
import * as uuid from "uuid";

interface Props extends Partial<TooltipProps> {
  children: React.ReactNode;
  renderAnchor: (props: { id: string }) => React.ReactNode;
}

export function StatefulTooltip({ children, renderAnchor, ...rest }: Props) {
  const id = useRef("tooltip-" + uuid.v4());
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {renderAnchor({ id: id.current })}
      <Tooltip
        target={id.current}
        isOpen={isOpen}
        toggle={() => setIsOpen(cur => !cur)}
        {...rest}
      >
        {children}
      </Tooltip>
    </>
  );
}
