import React, { useState } from "react";
import { useSocket } from "../socket";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

interface Props {
  placeholder?: string;
  kind?: string;
  onSelect?: (result?: SearchResult) => void;
  selectedPrefix?: string;
  autoFocus?: boolean;
  invalid?: boolean;
  initialSelection?: SearchResult;
  className?: string;
}

export interface SearchResult {
  name: string;
  uid: string;
}

export function EntityPicker({
  placeholder,
  kind,
  onSelect,
  selectedPrefix,
  autoFocus,
  invalid,
  initialSelection,
  className,
}: Props) {
  const socket = useSocket();
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<SearchResult[]>([]);
  const [selected, setSelected] = useState<SearchResult | undefined>(
    initialSelection
  );

  const handleSearch = (query: string) => {
    setIsLoading(true);
    setSelected(undefined);
    socket.emit(
      "searchEntities",
      { kind, name: query },
      (data: SearchResult[]) => {
        setOptions(data);
        setIsLoading(false);
      }
    );
  };

  const handleChange = (results: SearchResult[]) => {
    setSelected(results[0]);
    if (onSelect) onSelect(results[0]);
  };

  if (selected) {
    return (
      <div className={classNames("d-flex align-items-center", className)}>
        <div className="mr-3">
          {selectedPrefix} {selected.name}
        </div>
        <FontAwesomeIcon
          style={{ cursor: "pointer" }}
          icon={faTimesCircle}
          onClick={() => setSelected(undefined)}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <AsyncTypeahead
        id="entity-picker"
        onSearch={handleSearch}
        onChange={handleChange}
        isLoading={isLoading}
        options={options}
        delay={100}
        placeholder={placeholder || "Search for something..."}
        labelKey="name"
        autoFocus={autoFocus}
        isInvalid={invalid}
        defaultSelected={selected}
      />
    </div>
  );
}
