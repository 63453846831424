import React from "react";
import { Modal } from "reactstrap";
import { useFetcher } from "../../hooks";
import { InteractionDetails } from "../../types";
import EditorForm from "./EditorForm";
import * as uuid from "uuid";

interface Props {
  entityUid: string;
  interactionUid: string;
  onSave: (data: InteractionDetails) => void;
  onCancel: () => void;
}

const InteractionEditor = ({
  entityUid,
  interactionUid,
  onSave,
  onCancel,
}: Props) => {
  const { data, loading } = useFetcher<InteractionDetails>(
    "getInteraction",
    {
      uid: interactionUid,
      entityUid,
    },
    { condition: interactionUid !== "new" }
  );

  if (loading) return null;

  const defaults: InteractionDetails = {
    label: "",
    source: "",
    uid: uuid.v4(),
    validTargets: [],
    customTargets: [],
  };

  return (
    <Modal isOpen size="xl">
      <EditorForm
        details={data ?? defaults}
        entityUid={entityUid}
        onCancel={onCancel}
        onSubmit={onSave}
      />
    </Modal>
  );
};

export default InteractionEditor;
