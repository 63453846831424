import React, { useState, useCallback } from "react";
import { useSocketListener } from "../../hooks";
import { Interactable } from "./shared";
import { useInteractionMenu } from "../InteractionMenuContext";

export function Inventory() {
  const [inv, setInv] = useState<{ name: string; uid: string }[]>([]);
  const { openInteractionMenu } = useInteractionMenu();
  useSocketListener("inventory", useCallback(setInv, [setInv]));
  const sortedInv = [...inv].sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
  return (
    <div>
      <h5>Inventory</h5>
      {sortedInv.length === 0 && <div>You aren't carrying anything.</div>}
      {sortedInv.length > 0 &&
        sortedInv.map(i => (
          <div key={i.uid}>
            <Interactable
              onClick={(e: React.MouseEvent) =>
                openInteractionMenu(e.pageX, e.pageY, i.uid)
              }
            >
              {i.name}
            </Interactable>
          </div>
        ))}
    </div>
  );
}
