import React from "react";
import styled from "styled-components";
import { LineEvent } from "../../events";
import { TInteractionMenuContext } from "../InteractionMenuContext";
import { Stack } from "../../leetcode";
import { InlinePrompt } from "./InlinePrompt";
import { InlineButtons } from "./InlineButtons";

interface Props {
  line: LineEvent;
  openInteractionMenu: TInteractionMenuContext["openInteractionMenu"];
}

const Wrapper = styled.div<{ indicatorColor?: string }>`
  border-left: ${({ indicatorColor }) =>
    indicatorColor ? `3px solid ${indicatorColor}` : "none"};
  white-space: pre-wrap;
  background: #fffefb;
  padding: 5px ${({ indicatorColor }) => (indicatorColor ? "7px" : "10px")};
  color: #f3f3f3;
  &:nth-child(odd) {
    background: #252525;
  }
  &:nth-child(even) {
    background: #2f2f2f;
  }
`;
const PlainText = styled.span``;
const Reference = styled.span`
  text-decoration: underline dashed;
  text-underline-position: under;
  cursor: pointer;
`;

export const Line = React.memo(({ line, openInteractionMenu }: Props) => {
  const handleClickReference = async (
    event: React.MouseEvent,
    referencesUid: string
  ) => {
    openInteractionMenu(event.pageX, event.pageY, referencesUid);
  };
  const pieces = parseLineText(line.text);

  let indicatorColor: string | undefined;
  if (line.flags.chat) {
    indicatorColor = "#5884d5";
  }

  return (
    <Wrapper indicatorColor={indicatorColor}>
      {pieces.map((piece, index) =>
        piece.referencesUid ? (
          <Reference
            key={index}
            onClick={(event) =>
              handleClickReference(event, piece.referencesUid!)
            }
            style={piece.style}
          >
            {piece.text}
          </Reference>
        ) : (
          <PlainText key={index} style={piece.style}>
            {piece.text}
          </PlainText>
        )
      )}
      {line.ui && (
        <div className="mt-1 mb-1">
          {line.ui?.component === "prompt" && <InlinePrompt prompt={line.ui} />}
          {line.ui?.component === "buttons" && <InlineButtons ui={line.ui} />}
        </div>
      )}
    </Wrapper>
  );
});

interface LinePiece {
  text: string;
  referencesUid?: string;
  style: {
    color: string;
    background: string;
  };
}

function parseLineText(text: string): LinePiece[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, "text/html");

  const color = new Stack<string>();
  const background = new Stack<string>();
  const result: LinePiece[] = [];

  function style() {
    return { color: color.peek(), background: background.peek() };
  }

  function dfs(nodes: NodeListOf<ChildNode>) {
    // downLevelIterators flag doesn't seem to be recognized?
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      if (node.nodeType === Node.TEXT_NODE) {
        result.push({ text: node.textContent!, style: style() });
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const untypedNode = node as any;
        const toPop: Stack<string>[] = [];

        // why is the DOM screaming at me?
        if (node.nodeName === "REFERENCE") {
          result.push({
            text: node.textContent!,
            referencesUid: untypedNode.getAttribute("to"),
            style: style(),
          });
        } else if (node.nodeName === "COLOR") {
          if (untypedNode.getAttribute("text")) {
            color.push(untypedNode.getAttribute("text"));
            toPop.push(color);
          }
          if (untypedNode.getAttribute("background")) {
            background.push(untypedNode.getAttribute("background"));
            toPop.push(background);
          }
        }

        if (node.nodeName !== "REFERENCE") {
          dfs(node.childNodes);
        }

        toPop.forEach((i) => i.pop());
      }
    }
  }
  dfs(doc.body.childNodes);

  return result;
}
