import React from "react";
import { useForm } from "react-hook-form";
import { request } from "../httpClient";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Form, FormGroup, Input, Button, FormFeedback } from "reactstrap";

interface RegistrationForm {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  pronouns: string;
}

const Wrapper = styled.div`
  width: 400px;
  max-width: 100%;
  margin: 150px auto;
`;

const required = "This field is required.";

export const Register = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, watch } = useForm<RegistrationForm>();
  const onSubmit = async (values: RegistrationForm) => {
    const res = await request("/register", { method: "POST", body: values });
    if (res.ok) {
      history.push("/play");
    }
  };

  return (
    <Wrapper>
      <h3 className="mb-3">Sign Up</h3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <label htmlFor="name">Username</label>
          <Input
            type="text"
            id="name"
            name="name"
            innerRef={register({ required })}
            invalid={!!errors.name}
          />
          <FormFeedback>{errors.name?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="email">Email</label>
          <Input
            type="email"
            id="email"
            name="email"
            innerRef={register({ required })}
            invalid={!!errors.email}
          />
          <FormFeedback>{errors.email?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="password">Password</label>
          <Input
            type="password"
            id="password"
            name="password"
            innerRef={register({ required })}
            invalid={!!errors.password}
          />
          <FormFeedback>{errors.password?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="confirmPassword">Confirm password</label>
          <Input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            innerRef={register({
              required,
              validate: value =>
                value === watch("password") || "Passwords do not match.",
            })}
            invalid={!!errors.confirmPassword}
          />
          <FormFeedback>{errors.confirmPassword?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="pronouns">Pronouns</label>
          <Input
            type="select"
            id="pronouns"
            name="pronouns"
            innerRef={register}
            invalid={!!errors.pronouns}
          >
            <option value="he">He/him</option>
            <option value="her">She/her</option>
            <option value="they">They/them</option>
          </Input>
        </FormGroup>
        <Button type="submit" color="success">
          Submit
        </Button>
      </Form>
    </Wrapper>
  );
};
