import { request } from "../httpClient";
import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form, FormFeedback, FormGroup, Input } from "reactstrap";
import { Wrapper } from "../components/layout";
import { useHistory } from "react-router-dom";

interface FormFields {
  password: string;
  confirmPassword: string;
}
const required = "This field is required.";

export function ResetPassword() {
  const history = useHistory();
  const { register, handleSubmit, errors, watch } = useForm<FormFields>();
  const onSubmit = async (values: FormFields) => {
    const token = new URL(window.location.toString()).searchParams.get("token");
    const res = await request("/resetpassword", {
      method: "POST",
      body: { password: values.password, token },
    });
    if (res.ok) {
      const { playerName } = await res.json();
      await request("/login", {
        method: "POST",
        body: { password: values.password, username: playerName },
      });
      history.push("/play");
    }
  };

  return (
    <Wrapper>
      <h3 className="mb-3">Reset your password</h3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <label htmlFor="password">Password</label>
          <Input
            type="password"
            id="password"
            name="password"
            innerRef={register({ required })}
            invalid={!!errors.password}
          />
          <FormFeedback>{errors.password?.message}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <label htmlFor="confirmPassword">Confirm password</label>
          <Input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            innerRef={register({
              required,
              validate: (value) =>
                value === watch("password") || "Passwords do not match.",
            })}
            invalid={!!errors.confirmPassword}
          />
          <FormFeedback>{errors.confirmPassword?.message}</FormFeedback>
        </FormGroup>
        <Button type="submit" color="success">
          Submit
        </Button>
      </Form>
    </Wrapper>
  );
}
