import React, { useEffect } from "react";
import { Form, FormGroup, Input, Button, Label } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { EntityPicker, SearchResult } from "../../components/EntityPicker";
import { getReverse } from "./util";
import { useSocket } from "../../socket";
import { useActivePlayerContext } from "../../player";

interface Props {
  onClose: () => void;
}

interface FormFields {
  name: string;
  toRoom: SearchResult;
  createReverse?: boolean;
  reverseName?: string;
}

const required = "This field is required.";

export function CreateExitForm({ onClose }: Props) {
  const socket = useSocket();
  const player = useActivePlayerContext();
  const { handleSubmit, register, errors, control, watch, setValue } = useForm<
    FormFields
  >();
  const createReverse = watch("createReverse");
  const name = watch("name");

  useEffect(() => {
    if (createReverse) {
      setValue("reverseName", getReverse(name));
    }
  }, [createReverse]);

  const onSubmit = handleSubmit(({ toRoom, ...fields }) => {
    socket.emit("create.exit", {
      ...fields,
      toRoomUid: toRoom.uid,
      fromRoomUid: player.locationUid,
    });
    onClose();
  });

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Input
          placeholder="Direction"
          name="name"
          autoComplete="off"
          innerRef={register({ required })}
          invalid={!!errors.name}
        />
      </FormGroup>
      <FormGroup>
        <Controller
          control={control}
          name="toRoom"
          as={EntityPicker}
          placeholder="Destination"
          kind="room"
          onChangeName="onSelect"
          selectedPrefix="Destination:"
          rules={{ required }}
          invalid={!!errors.toRoom}
        />
      </FormGroup>
      <FormGroup check className="mb-3">
        <Label check>
          <Input type="checkbox" name="createReverse" innerRef={register} />{" "}
          Create reverse exit?
        </Label>
      </FormGroup>
      {createReverse && (
        <FormGroup>
          <Input
            placeholder="Reverse Direction"
            name="reverseName"
            innerRef={register({ required })}
            invalid={!!errors.reverseName}
            autoComplete="off"
          />
        </FormGroup>
      )}
      <Button className="mr-3" color="primary">
        Create
      </Button>
      <Button onClick={onClose}>Cancel</Button>
    </Form>
  );
}
