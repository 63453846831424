import React, { useState } from "react";
import { Button, Input, ListGroup, ListGroupItem } from "reactstrap";
import styled from "styled-components";
import { useSocket } from "../../socket";
import { EntityInfo } from "../Feed/hooks";

interface Props {
  entity: EntityInfo;
  onEdit: (uid: string) => void;
}

export function Details({ entity, onEdit }: Props) {
  const socket = useSocket();
  const [isAdding, setIsAdding] = useState(false);
  const [newDetailName, setNewDetailName] = useState("");

  const handleCreateDetail = () => {
    socket.emit("create.detail", {
      name: newDetailName,
      detailParentUid: entity.uid,
    });
    setIsAdding(false);
  };

  return (
    <ListGroup>
      {entity.details.map((detail) => (
        <ListGroupItem>
          <Row>
            {detail.name}
            <Button
              outline
              color="info"
              size="sm"
              onClick={() => onEdit(detail.uid)}
            >
              Edit
            </Button>
          </Row>
        </ListGroupItem>
      ))}
      <ListGroupItem>
        {isAdding ? (
          <div className="d-flex align-items-center">
            Name:{" "}
            <Input
              value={newDetailName}
              onChange={(e) => setNewDetailName(e.target.value)}
              className="ml-2 flex-grow-1 mr-2"
            />
            <Button color="primary" onClick={handleCreateDetail}>
              Create
            </Button>
          </div>
        ) : (
          <Button onClick={() => setIsAdding(true)} color="primary">
            New detail
          </Button>
        )}
      </ListGroupItem>
    </ListGroup>
  );
}

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
`;
