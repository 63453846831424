import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  timeout: number;
}

export function UiTimeout({ timeout }: Props) {
  const [pct, setPct] = useState(0);
  const mountedAt = useRef(Date.now());
  const interval = useRef<number>();
  useEffect(() => {
    interval.current = setInterval(() => {
      const elapsed = (Date.now() - mountedAt.current) / 1000;
      setPct((elapsed / timeout) * 100);
    }, 25);
    return () => {
      clearInterval(interval.current);
      interval.current = undefined;
    };
  }, [timeout]);
  useEffect(() => {
    if (pct >= 100 && interval.current) {
      clearInterval(interval.current);
    }
  }, [pct]);
  return pct < 100 ? <TimeoutPie pct={pct} /> : null;
}

// +/- 1% to achieve slight blurring effect to make gradient look less jagged
// https://medium.com/pixel-and-ink/avoiding-jagged-edges-on-gradients-f485cc7401f5
const TimeoutPie = styled.div.attrs<{ pct: number }>(({ pct }) => ({
  style: {
    backgroundImage: `conic-gradient(
      transparent ${pct - 1}%,
      white ${pct + 1}%
    )`,
  },
}))<{ pct: number }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
`;
