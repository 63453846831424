import React, { useState, useEffect, useRef } from "react";
import Feed from "./Feed/Feed";
import styled from "styled-components";
import { ActionPanel } from "./ActionPanel/ActionPanel";
import { CreateRoomParams } from "./ActionPanel/CreateRoomForm";
import { CreateThingParams } from "./ActionPanel/CreateThingForm";
import { SocketContext, useSocket } from "../socket";
import io from "socket.io-client";
import { Chat } from "./Chat/Chat";
import { QuickActions } from "./QuickActions/QuickActions";
import { InteractionMenuManager } from "./InteractionMenu";
import { TopBar } from "./TopBar/TopBar";
import { Entity, Player } from "../types";
import { ActivePlayerContext } from "../player";
import { LeftBar } from "./LeftBar/LeftBar";
import { FirstTimeIntro } from "./Help/IntroModal";
import {
  GlobalMutationCallback,
  GlobalMutationContext,
  IGlobalMutationContext,
} from "../globalMutation";

const Screen = styled.div`
  display: flex;
  height: 100vh;
  flex-flow: column;
`;
const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-flow: row;
  overflow: hidden;
`;
const Middle = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
`;

export function MainWrapper() {
  const [socket, setSocket] = useState<SocketIOClient.Socket | undefined>();
  useEffect(() => {
    setSocket(io(process.env.REACT_APP_SOCKET_HOST!));
  }, []);

  const [activePlayer, setActivePlayer] = useState<Player | undefined>();
  useEffect(() => {
    if (!socket) return;

    const handleLocationChange = (location: Entity) => {
      setActivePlayer((val) => val && { ...val, locationUid: location.uid });
    };

    socket.on("activePlayer", setActivePlayer);
    socket.on("location", handleLocationChange);
    return () => {
      socket.off("activePlayer", setActivePlayer);
      socket.off("location", handleLocationChange);
    };
  }, [socket]);

  const globalMutationSubscribers = useRef<
    Record<string, GlobalMutationCallback[]>
  >({});
  const globalMutationContext: IGlobalMutationContext = {
    subscribe: (topic: string, callback: GlobalMutationCallback) => {
      const val = globalMutationSubscribers.current;
      const subs = val[topic] ?? [];
      subs.push(callback);
      globalMutationSubscribers.current = { ...val, [topic]: subs };
    },
    publish: (topic: string, data: any) => {
      const subs = globalMutationSubscribers.current[topic];
      if (subs) {
        subs.forEach((sub) => sub(data));
      }
    },
    unsubscribe: (topic: string, callback: GlobalMutationCallback) => {
      const val = globalMutationSubscribers.current;
      const subs = val[topic] ?? [];
      const idx = subs.indexOf(callback);
      if (idx !== -1) subs.splice(idx, 1);
      globalMutationSubscribers.current = { ...val, [topic]: subs };
    },
  };

  if (!socket || !activePlayer) return null;

  return (
    <SocketContext.Provider value={socket}>
      <ActivePlayerContext.Provider value={activePlayer}>
        <GlobalMutationContext.Provider value={globalMutationContext}>
          <Main />
        </GlobalMutationContext.Provider>
      </ActivePlayerContext.Provider>
    </SocketContext.Provider>
  );
}

function Main() {
  const [editingEntity, setEditingEntity] = useState<string | null>(null);
  const socket = useSocket();

  const handleCreateRoom = (room: CreateRoomParams) => {
    socket.emit("create.room", room);
  };
  const handleCreateThing = (thing: CreateThingParams) => {
    socket.emit("create.thing", thing);
  };
  const handleEditEntity = (entityUid: string) => {
    setEditingEntity(entityUid);
  };

  return (
    <SocketContext.Provider value={socket}>
      <InteractionMenuManager onEdit={handleEditEntity}>
        <Screen>
          <TopBar />
          <InnerContainer>
            <LeftBar />
            <Middle>
              <Feed />
              <QuickActions />
              <Chat />
            </Middle>
            <ActionPanel
              onCreateRoom={handleCreateRoom}
              onCreateThing={handleCreateThing}
              editingEntityUid={editingEntity}
              onCloseEditor={() => setEditingEntity(null)}
              onChangeEntity={setEditingEntity}
            />
          </InnerContainer>
        </Screen>
        <FirstTimeIntro />
      </InteractionMenuManager>
    </SocketContext.Provider>
  );
}
