import {
  faMinusSquare,
  faPlusSquare,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { keyBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSocket } from "../../socket";
import { EntityInfo, useEntityFetcher } from "../Feed/hooks";
import { DeleteEntity } from "./DeleteEntity";
import { Details } from "./Details";
import { Interactions } from "./Interactions";
import { Properties } from "./Properties";
import { Triggers } from "./Triggers";

interface Props {
  uid: string;
  onClose: () => void;
  onChangeEntity: (uid: string) => void;
}

const EditEntityForm = ({ uid, onClose, onChangeEntity }: Props) => {
  const [entity, setEntity] = useState<EntityInfo | undefined>();
  const socket = useSocket();
  const { getEntity } = useEntityFetcher();

  const updateEntity = useCallback(
    async (data: EntityInfo) => {
      const forEachEntityProp = (
        callback: (key: string, uid: string) => void
      ) => {
        for (const key in data.properties) {
          const value = data.properties[key];
          if (typeof value === "object" && value.__entity_uid) {
            callback(key, value.__entity_uid);
          }
        }
      };
      if (uid === data.uid) {
        const toFetch: string[] = [];
        forEachEntityProp((key, uid) => toFetch.push(uid));
        const keyed = keyBy(
          await Promise.all(toFetch.map((uid) => getEntity(uid, []))),
          "uid"
        );
        forEachEntityProp((key, uid) => (data.properties[key] = keyed[uid]));
        setEntity(data);
      }
    },
    [uid]
  );

  useEffect(() => {
    let cleanedUp = false;
    socket.emit("subscribeToEntity", { uid });
    socket.emit("entity.get", { uid }, updateEntity);
    return () => {
      socket.emit("unsubscribeFromEntity", { uid });
      cleanedUp = true;
    };
  }, [uid, updateEntity]);

  useEffect(() => {
    socket.on("subscribedEntityUpdate", updateEntity);
    return () => void socket.off("subscribedEntityUpdate", updateEntity);
  }, [socket, uid, updateEntity]);

  if (!entity) return null;
  return (
    <div>
      <div style={{ float: "right", cursor: "pointer" }}>
        <FontAwesomeIcon icon={faTimesCircle} onClick={onClose} />
      </div>
      <h5>
        Editing “{entity.properties.name}” (#{entity.friendlyId})
      </h5>
      {entity.detailParent && (
        <h6>
          (a detail of{" "}
          <DetailParentLink
            onClick={() => onChangeEntity(entity.detailParent!.uid)}
          >
            “{entity.detailParent.name}”
          </DetailParentLink>
          )
        </h6>
      )}
      <EditSection title="Interactions">
        <Interactions entity={entity} />
      </EditSection>
      <EditSection title="Properties">
        <Properties entity={entity} />
      </EditSection>
      <EditSection title="Details">
        <Details entity={entity} onEdit={onChangeEntity} />
      </EditSection>
      <EditSection title="Triggers">
        <Triggers entity={entity} />
      </EditSection>
      <DeleteEntity entity={entity} onDeleted={() => onClose()} />
    </div>
  );
};

const EditSection = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(true);
  return (
    <div>
      <h6
        className="mt-3"
        onClick={() => setOpen((val) => !val)}
        style={{ cursor: "pointer" }}
      >
        <FontAwesomeIcon
          icon={open ? faMinusSquare : faPlusSquare}
          className="mr-2"
        />
        {title}
      </h6>
      {open && children}
    </div>
  );
};

const DetailParentLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export default EditEntityForm;
