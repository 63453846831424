import React, { useState } from "react";
import { useSocket } from "../../socket";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { EntityPicker, SearchResult } from "../../components/EntityPicker";

export function Teleporter() {
  const socket = useSocket();
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (result?: SearchResult) => {
    setIsOpen(false);
    if (result) {
      socket.emit("teleport", { uid: result.uid });
    }
  };

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(open => !open)}
      className="mt-3 mb-3"
    >
      <DropdownToggle caret>Teleport...</DropdownToggle>
      <DropdownMenu>
        <EntityPicker
          kind="room"
          placeholder="Search for a room..."
          onSelect={handleSelect}
          autoFocus
        />
      </DropdownMenu>
    </Dropdown>
  );
}
