import React, { useEffect, useState, useRef } from "react";
import { LineEvent } from "../../events";
import styled from "styled-components";
import { Line } from "./Line";
import { useSocket } from "../../socket";
import { useInteractionMenu } from "../InteractionMenuContext";

const Container = styled.div`
  overflow-y: auto;
  background: #252525;
  flex: 1;
`;

const MAX_BUFFER = 1000;

const Feed = () => {
  const socket = useSocket();
  const [lines, setLines] = useState<LineEvent[]>([]);
  useEffect(() => {
    socket.on("line", (event: LineEvent) => {
      setLines(current => {
        let newValue = [...current, event];
        if (newValue.length > MAX_BUFFER) {
          newValue = newValue.slice(newValue.length - MAX_BUFFER);
        }
        return newValue;
      });
      if (containerRef.current) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
    });
  }, [socket]);
  const containerRef = useRef<HTMLDivElement>(null);
  const { openInteractionMenu } = useInteractionMenu();
  return (
    <Container ref={containerRef}>
      {lines.map(i => (
        <Line line={i} key={i.uid} openInteractionMenu={openInteractionMenu} />
      ))}
    </Container>
  );
};

export default Feed;
