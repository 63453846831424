import React, { useState } from "react";
import styled from "styled-components";
import { ActivePlayers } from "./ActivePlayers";
import { Inventory } from "./Inventory";
import { useDragToResize } from "../../hooks";
import { Compass } from "./Compass";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
`;
const WidgetWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  &:not(:last-of-type) {
    border-bottom: 1px solid white;
  }
`;
const Handle = styled.div`
  width: 8px;
  height: 100%;
  position: absolute;
  cursor: col-resize;
  border-radius: 5px;
  transform: translateX(50%);
  right: 0;
`;

export function LeftBar() {
  const [width, setWidth] = useState(250);

  const handleMouseDown = useDragToResize(event => setWidth(event.clientX));
  return (
    <Container style={{ width }}>
      <Handle onMouseDown={handleMouseDown} />
      <WidgetWrapper>
        <Compass />
      </WidgetWrapper>
      <WidgetWrapper>
        <Inventory />
      </WidgetWrapper>
      <WidgetWrapper>
        <ActivePlayers />
      </WidgetWrapper>
    </Container>
  );
}
