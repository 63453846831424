import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import { useSocket } from "../../socket";
import { EntityInfo } from "../Feed/hooks";

interface Props {
  entity: EntityInfo;
  onDeleted: () => void;
}

export function DeleteEntity({ entity, onDeleted }: Props) {
  const [showingModal, setShowingModal] = useState(false);
  const socket = useSocket();

  const handleDelete = () => {
    socket.emit("deleteEntity", { uid: entity.uid });
    onDeleted();
  };

  if (entity.kind === "player") return null;

  return (
    <>
      <Button
        color="danger"
        outline
        className="mt-3"
        onClick={() => setShowingModal(true)}
      >
        Delete “{entity.properties.name}”
      </Button>
      <Modal isOpen={showingModal}>
        <div className="m-4">
          <p>Really delete “{entity.properties.name}”?</p>
          {entity.kind === "room" && (
            <p>
              All adjoining exits will also be deleted, and all contents will be
              ejected.
            </p>
          )}
          <p>You can't undo this.</p>
          <Button color="danger" className="mr-3" onClick={handleDelete}>
            Delete
          </Button>
          <Button color="secondary" onClick={() => setShowingModal(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}
