import { useEffect, MutableRefObject, useState } from "react";
import { useSocket } from "../../socket";

export interface Interaction {
  uid: string;
  label: string;
  inherited: boolean;
  visibilityCondition?: string;
  validTargets: string[];
  isClientSide?: boolean;
}

export interface Exit {
  name: string;
  uid: string;
  toRoomUid: string;
}

export interface Trigger {
  uid: string;
  triggerType: string;
  label: string;
  inherited: boolean;
}

export interface EntityInfo {
  friendlyId: number;
  uid: string;
  kind: string;
  ownerUid: string;
  properties: Record<string, any>;
  interactions: Interaction[];
  exits?: Exit[];
  locationUid: string;
  details: { name: string; uid: string }[];
  detailParent?: { name: string; uid: string };
  triggers: Trigger[];
}

export type Target = null | {
  type: "entity" | "custom";
  uid: string;
  name: string;
};

export const useEntityFetcher = () => {
  const socket = useSocket();

  const getEntity = (uid: string, fields?: string[]): Promise<EntityInfo> => {
    return new Promise((resolve) => {
      socket.emit("entity.get", { uid, fields }, resolve);
    });
  };

  return { getEntity };
};

export const useGetEntity = (uid: string) => {
  const socket = useSocket();
  const [entity, setEntity] = useState<EntityInfo | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [version, setVersion] = useState(1);

  useEffect(() => {
    if (!uid) return;
    setLoading(true);
    socket.emit("entity.get", { uid }, (data: EntityInfo) => {
      setEntity(data);
    });
  }, [uid, socket, version]);

  const refetch = () => {
    // force the fetch effect to re-run
    setVersion((v) => v + 1);
  };

  return { entity, loading, refetch };
};

export const useDetectClickOutside = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: () => void
) => {
  useEffect(() => {
    const handler = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };
    document.addEventListener("click", handler);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, [ref, callback]);
};
