import React, { useEffect, useState } from "react";
import MonacoEditor from "react-monaco-editor";
import {
  Badge,
  Button,
  Form,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Modal,
} from "reactstrap";
import { SimpleDeleteModal } from "../../components/SimpleDeleteModal";
import { useSocket } from "../../socket";
import { TriggerDetails } from "../../types";
import { EntityInfo, Trigger } from "../Feed/hooks";
import { MONACO_OPTIONS } from "./util";

interface Props {
  entity: EntityInfo;
}

const triggerTypeOptions = [
  { key: "chat", label: "Chat message" },
  { key: "enteredContents", label: "Another entity entered this entity" },
  {
    key: "enteredLocation",
    label: "Another entity entered this entity's location",
  },
  { key: "exitedContents", label: "Another entity exited this entity" },
  {
    key: "exitedLocation",
    label: "Another entity exited this entity's location",
  },
  { key: "moved", label: "This entity moved" },
];

export function Triggers({ entity }: Props) {
  const socket = useSocket();
  const [isAdding, setIsAdding] = useState(false);
  const [addingType, setAddingType] = useState<string | undefined>();
  const [editing, setEditing] = useState<string | undefined>();

  const handleEdit = (trigger: Trigger) => {
    setEditing(trigger.uid);
  };
  const handleCreate = () => {
    socket.emit("editTrigger", {
      entityUid: entity.uid,
      triggerType: addingType,
      source: "",
    });
  };

  return (
    <ListGroup>
      {entity.triggers.map((trigger) => (
        <ListGroupItem
          key={trigger.uid}
          className="d-flex align-items-center justify-content-between"
        >
          <div className="d-flex align-items-center">
            <span className="mr-2">{trigger.label}</span>
            {trigger.inherited && (
              <Badge className="ml-2" color="secondary">
                Inherited
              </Badge>
            )}
          </div>
          <Button
            size="sm"
            color="info"
            outline
            onClick={() => handleEdit(trigger)}
          >
            {trigger.inherited ? "Override" : "Edit"}
          </Button>
        </ListGroupItem>
      ))}
      <ListGroupItem>
        {!isAdding && (
          <Button onClick={() => setIsAdding(true)} color="primary">
            Add trigger
          </Button>
        )}
        {isAdding && (
          <div className="d-flex align-items-center">
            <Input
              type="select"
              placeholder="Select trigger condition..."
              value={addingType}
              onChange={(e) => setAddingType(e.target.value)}
              className="mr-2"
            >
              <option hidden value="">
                Select condition
              </option>
              {triggerTypeOptions.map((opt) => (
                <option key={opt.key} value={opt.key}>
                  {opt.label}
                </option>
              ))}
            </Input>
            <Button onClick={handleCreate} color="primary">
              Save
            </Button>
          </div>
        )}
      </ListGroupItem>
      {editing && (
        <TriggerEditor
          uid={editing}
          entityUid={entity.uid}
          onClose={() => setEditing(undefined)}
        />
      )}
    </ListGroup>
  );
}

function TriggerEditor({
  uid,
  entityUid,
  onClose,
}: {
  uid: string;
  entityUid: string;
  onClose: () => void;
}) {
  const [trigger, setTrigger] = useState<TriggerDetails | undefined>();
  const socket = useSocket();
  const [showingDeleteModal, setShowingDeleteModal] = useState(false);

  useEffect(() => {
    socket.emit("getTrigger", { uid, entityUid }, (data: TriggerDetails) => {
      setTrigger(data);
    });
  }, [socket, setTrigger]);

  if (!trigger) return null;

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    socket.emit("editTrigger", { entityUid, triggerUid: uid, ...trigger });
    onClose();
  };

  const onDelete = () => {
    socket.emit("deleteTrigger", { entityUid, uid });
    onClose();
  };

  return (
    <Modal size="xl" isOpen>
      <Form onSubmit={onSubmit} className="m-3">
        <h5>Trigger Editor</h5>
        <FormGroup className="d-flex align-items-center">
          <div className="mr-2">Label:</div>
          <Input
            value={trigger.label ?? ""}
            onChange={(e) => {
              const label = e.target.value;
              setTrigger((v) => ({ ...(v as TriggerDetails), label }));
            }}
            style={{ maxWidth: 500 }}
          />
        </FormGroup>
        <FormGroup className="d-flex align-items-center">
          <div className="mr-2">Trigger type:</div>
          <Input
            type="select"
            value={trigger.triggerType}
            onChange={(e) => {
              const triggerType = e.target.value;
              setTrigger((v) => ({ ...(v as TriggerDetails), triggerType }));
            }}
            style={{ maxWidth: 500 }}
          >
            {triggerTypeOptions.map(({ key, label }) => (
              <option key={key} value={key}>
                {label}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <MonacoEditor
            width="100%"
            height="600"
            language="python"
            theme="vs-dark"
            options={MONACO_OPTIONS}
            onChange={(val) =>
              setTrigger((t) => ({ ...(t as TriggerDetails), source: val }))
            }
            value={trigger.source}
          />
        </FormGroup>
        <div className="d-flex">
          <Button type="submit" color="primary" className="mr-3">
            Save
          </Button>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => setShowingDeleteModal(true)}
            color="danger"
            className="ml-auto"
          >
            Delete
          </Button>
        </div>
      </Form>
      {showingDeleteModal && (
        <SimpleDeleteModal
          header="Really delete this trigger?"
          onDelete={onDelete}
          onCancel={() => setShowingDeleteModal(false)}
        />
      )}
    </Modal>
  );
}
