import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useSocketListener } from "../../hooks";
import { EntityInfo, Exit } from "../Feed/hooks";
import { Button } from "reactstrap";
import { Teleporter } from "../ActionPanel/Teleporter";
import { useSocket } from "../../socket";

const Wrapper = styled.div``;

export const Compass = () => {
  const [location, setLocation] = useState<EntityInfo | null>(null);
  const socket = useSocket();
  useSocketListener("location", useCallback(setLocation, [setLocation]));
  if (!location) return null;

  const handleMove = (exit: Exit) => {
    socket.emit("entity.interact", {
      entityUid: exit.uid,
      interactionLabel: "go",
    });
  };

  return (
    <Wrapper>
      <h5>Location: {location.properties.name}</h5>
      <h6>Available Exits</h6>
      {location.exits?.map(exit => (
        <Button
          outline
          color="info"
          key={exit.name}
          onClick={() => handleMove(exit)}
          className="mr-2"
        >
          {exit.name}
        </Button>
      ))}
      {!location.exits?.length && <p>(No exits available.)</p>}
      <Teleporter />
    </Wrapper>
  );
};
