import React, { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";
import MonacoEditor from "react-monaco-editor";
import { Button, Form, FormGroup, Input, Modal } from "reactstrap";
import { SimpleDeleteModal } from "../../components/SimpleDeleteModal";
import { useSocket } from "../../socket";
import { InteractionDetails } from "../../types";
import { MONACO_OPTIONS } from "./util";

interface Props {
  details: InteractionDetails;
  entityUid: string;
  onSubmit: (params: InteractionDetails) => void;
  onCancel: () => void;
}

interface FormFields {
  label: string;
  source: string;
  validTargets: string[];
  customTargets: string[];
}

const targetOptions = [
  { key: "localPlayers", label: "Players in room" },
  { key: "localEntities", label: "Anything in room" },
  { key: "playerInventory", label: "Player inventory" },
  { key: "contents", label: "Contents of this object" },
  { key: "connectedPlayers", label: "All connected players" },
  { key: "custom", label: "Custom targets" },
  { key: "nothing", label: "Nothing (i.e. targeting is optional)" },
];

const EditorForm = ({ details, entityUid, onSubmit, onCancel }: Props) => {
  const { register, handleSubmit, control, watch } = useForm<FormFields>({
    defaultValues: details,
  });
  const validTargets = watch("validTargets");
  const socket = useSocket();
  const [showingDeleteModal, setShowingDeleteModal] = useState(false);

  const submit = handleSubmit((data) => {
    onSubmit({ ...details, ...data });
  });

  const onDelete = () => {
    socket.emit("deleteInteraction", { uid: details.uid, entityUid });
    onCancel();
  };

  return (
    <Form onSubmit={submit} className="m-3">
      <h5 className="mb-3">Interaction Editor</h5>
      <FormGroup className="d-flex align-items-center">
        <div>Name:</div>
        <Input
          innerRef={register}
          name="label"
          autoComplete="off"
          className="ml-3 w-25"
        />
      </FormGroup>
      <FormGroup className="d-flex align-items-center">
        <div>Targets:</div>
        <Controller
          name="validTargets"
          as={Typeahead as any}
          control={control}
          options={targetOptions}
          defaultSelected={targetOptions.filter((i) =>
            details.validTargets.includes(i.key)
          )}
          labelKey={(item: any) => item.label}
          id="validTargets"
          multiple
          className="ml-3 w-50"
          placeholder="Select targets, or leave blank for none."
          onChange={([items]) => items.map((i: any) => i.key)}
        />
      </FormGroup>
      {validTargets.includes("custom") && (
        <FormGroup className="d-flex align-items-center">
          <div>Custom targets:</div>
          <Controller
            name="customTargets"
            as={Typeahead as any}
            control={control}
            options={details.customTargets.map((target) => ({
              id: target,
              label: target,
            }))}
            defaultSelected={details.customTargets.map((target) => ({
              id: target,
              label: target,
            }))}
            labelKey="label"
            id="customTargets"
            multiple
            className="ml-3 w-50"
            placeholder=""
            allowNew
            onChange={([items]) => items.map((i: any) => i.label)}
          />
        </FormGroup>
      )}
      <FormGroup>
        <Controller
          name="source"
          as={MonacoEditor}
          control={control}
          width="100%"
          height="600"
          language="python"
          theme="vs-dark"
          options={MONACO_OPTIONS}
        />
      </FormGroup>
      <div className="d-flex">
        <Button type="submit" color="primary" className="mr-3">
          Save
        </Button>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => setShowingDeleteModal(true)}
          color="danger"
          className="ml-auto"
        >
          Delete
        </Button>
      </div>
      {showingDeleteModal && (
        <SimpleDeleteModal
          header="Really delete this interaction?"
          onDelete={onDelete}
          onCancel={() => setShowingDeleteModal(false)}
        />
      )}
    </Form>
  );
};

export default EditorForm;
