import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";
import { LineUIPrompt } from "../../events";
import { useSocket } from "../../socket";
import { InlineButton } from "./InlineButton";
import { UiTimeout } from "./UiTimeout";

interface Props {
  prompt: LineUIPrompt;
}

export function InlinePrompt({ prompt }: Props) {
  const [disabled, setDisabled] = useState(false);
  const [text, setText] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const socket = useSocket();
  const submitForm = (event: FormEvent) => {
    event.preventDefault();
    submit();
  };
  const submit = () => {
    socket.emit("activateInlineUi", { uid: prompt.uid, value: text });
    setDisabled(true);
    setSubmitted(true);
  };
  useEffect(() => {
    if (!submitted) {
      const timeoutId = setTimeout(() => {
        setDisabled(true);
        setText("");
      }, prompt.timeout * 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [prompt.timeout, submitted, setDisabled]);
  return (
    <form className="d-flex align-items-center" onSubmit={submitForm}>
      <Input
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="mr-2"
        style={{ maxWidth: 500 }}
        disabled={disabled}
      />
      <InlineButton
        button={prompt.button}
        onClick={submit}
        disabled={disabled}
      />
      {prompt.timeout && !submitted && !disabled && prompt.showTimer && (
        <UiTimeout timeout={prompt.timeout} />
      )}
    </form>
  );
}
