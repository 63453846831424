import React, { createContext, useContext, useState } from "react";
import { Player } from "./types";

export const ActivePlayerContext = createContext<Player | undefined>(undefined);

export const useActivePlayerContext = () => {
  const context = useContext(ActivePlayerContext);
  if (!context)
    throw new Error(
      "useActivePlayerContext must be used in a logged in context"
    );
  return context;
};

export const useAuth = () => {
  const [player, setPlayer] = useState<Player | undefined>();
  return { player, setPlayer };
};

export const Authenticated: React.FC = ({ children }) => {
  const { player } = useAuth();
  return (
    <ActivePlayerContext.Provider value={player}>
      {children}
    </ActivePlayerContext.Provider>
  );
};
