import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Input, Button, Label } from "reactstrap";
import { capitalize } from "../../utils/strings";

export interface CreateRoomParams {
  name: string;
  description: string;
  direction: string;
  createReverse: boolean;
  reverseDirection?: string;
}

interface Props {
  onCreateRoom: (room: CreateRoomParams) => void;
}

const required = "This field is required.";

const defaultReverseValues: Record<string, string> = {
  n: "s",
  ne: "sw",
  e: "w",
  se: "nw",
  s: "n",
  sw: "ne",
  w: "e",
  nw: "se",
  north: "south",
  northeast: "southwest",
  east: "west",
  southeast: "northwest",
  south: "north",
  southwest: "northeast",
  west: "east",
  northwest: "southeast",
  up: "down",
  down: "up",
};
for (const [key, value] of Object.entries(defaultReverseValues)) {
  defaultReverseValues[capitalize(key)] = capitalize(value);
}

const CreateRoomForm = ({ onCreateRoom }: Props) => {
  const { register, handleSubmit, watch, errors, setValue } = useForm<
    CreateRoomParams
  >();
  const createReverse = watch("createReverse");
  const direction = watch("direction");
  useEffect(() => {
    if (createReverse && direction in defaultReverseValues) {
      setValue("reverseDirection", defaultReverseValues[direction]);
    }
  }, [createReverse]);
  return (
    <Form onSubmit={handleSubmit(onCreateRoom)}>
      <FormGroup>
        <Input
          placeholder="Name"
          name="name"
          innerRef={register({ required })}
          invalid={!!errors.name}
          autoComplete="off"
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="textarea"
          placeholder="Description"
          name="description"
          innerRef={register}
          autoComplete="off"
        />
      </FormGroup>
      <FormGroup>
        <Input
          placeholder="Direction"
          name="direction"
          innerRef={register({ required })}
          invalid={!!errors.direction}
          autoComplete="off"
        />
      </FormGroup>
      <FormGroup check className="mb-3">
        <Label check>
          <Input type="checkbox" name="createReverse" innerRef={register} />{" "}
          Create reverse exit?
        </Label>
      </FormGroup>
      {createReverse && (
        <FormGroup>
          <Input
            placeholder="Reverse Direction"
            name="reverseDirection"
            innerRef={register({ required })}
            invalid={!!errors.reverseDirection}
            autoComplete="off"
          />
        </FormGroup>
      )}
      <Button type="submit" color="primary">
        Create
      </Button>
    </Form>
  );
};

export default CreateRoomForm;
