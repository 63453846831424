import React, { useEffect, useState } from "react";
import { Badge, Button, ListGroup, ListGroupItem } from "reactstrap";
import { useSocket } from "../../socket";
import { InteractionDetails } from "../../types";
import { EntityInfo, Interaction } from "../Feed/hooks";
import InteractionEditor from "./InteractionEditor";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  entity: EntityInfo;
}

export function Interactions({ entity }: Props) {
  const socket = useSocket();
  const [editingInteraction, setEditingInteraction] = useState<
    string | undefined
  >();
  const [isOverriding, setIsOverriding] = useState(false);
  const [optimisticallyUpdatedOrder, setOptimisticallyUpdatedOrder] = useState<
    undefined | Interaction[]
  >();

  const handleEditInteraction = (uid: string, override: boolean = false) => {
    setEditingInteraction(uid);
    setIsOverriding(override);
  };

  const handleInteractionSaved = (params: InteractionDetails) => {
    const interactionUid =
      isOverriding || editingInteraction === "new" ? "new" : editingInteraction;
    socket.emit(
      "editInteraction",
      { entityUid: entity.uid, interactionUid, ...params },
      () => {
        setEditingInteraction(undefined);
      }
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const uid = result.draggableId;
    const tmp = entity.interactions.map((i) => i.uid).filter((i) => i !== uid);
    const order = [
      ...tmp.slice(0, result.destination.index),
      uid,
      ...tmp.slice(result.destination.index),
    ];
    socket.emit("orderInteractions", {
      entityUid: entity.uid,
      orderedInteractionUids: order,
    });
    setOptimisticallyUpdatedOrder(
      order.map((uid) => entity.interactions.find((i) => i.uid === uid)!)
    );
  };

  useEffect(() => {
    setOptimisticallyUpdatedOrder(undefined);
  }, [entity]);

  const interactions = optimisticallyUpdatedOrder ?? entity.interactions;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <ListGroup>
              {interactions.map((i, index) => (
                <Draggable key={i.uid} draggableId={i.uid} index={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <ListGroupItem
                        key={i.uid}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <div className="d-flex align-items-center">
                          <div {...provided.dragHandleProps} className="mr-2">
                            <FontAwesomeIcon icon={faGripVertical} />
                          </div>
                          {i.label}
                          {i.inherited && (
                            <Badge className="ml-2" color="secondary">
                              Inherited
                            </Badge>
                          )}
                        </div>
                        <Button
                          size="sm"
                          color="info"
                          outline
                          onClick={() =>
                            handleEditInteraction(i.uid, i.inherited)
                          }
                        >
                          {i.inherited ? "Override" : "Edit"}
                        </Button>
                      </ListGroupItem>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <ListGroupItem>
                <Button
                  color="primary"
                  onClick={() => handleEditInteraction("new")}
                >
                  New Interaction
                </Button>
              </ListGroupItem>
            </ListGroup>
          </div>
        )}
      </Droppable>
      {editingInteraction && (
        <InteractionEditor
          entityUid={entity.uid}
          interactionUid={editingInteraction}
          onCancel={() => setEditingInteraction(undefined)}
          onSave={handleInteractionSaved}
        />
      )}
    </DragDropContext>
  );
}
