import React, { useState, useEffect } from "react";
import CreateRoomForm, { CreateRoomParams } from "./CreateRoomForm";
import CreateThingForm, { CreateThingParams } from "./CreateThingForm";
import EditEntityForm from "./EditEntityForm";
import styled from "styled-components";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { CreateExitForm } from "./CreateExitForm";

interface Props {
  onCreateRoom: (room: CreateRoomParams) => void;
  onCreateThing: (thing: CreateThingParams) => void;
  editingEntityUid: string | null;
  onCloseEditor: () => void;
  onChangeEntity: (uid: string) => void;
}

const Wrapper = styled.div`
  padding: 15px;
`;

const CreationZone = ({
  onCreateRoom,
  onCreateThing,
  editingEntityUid,
  onCloseEditor,
  onChangeEntity,
}: Props) => {
  const [activeZone, setActiveZone] = useState<
    null | "room" | "thing" | "exit" | "edit"
  >(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    setActiveZone(editingEntityUid ? "edit" : null);
  }, [editingEntityUid]);

  const handleCreateThing = (thing: CreateThingParams) => {
    setActiveZone(null);
    onCreateThing(thing);
  };

  const handleCreateRoom = (room: CreateRoomParams) => {
    setActiveZone(null);
    onCreateRoom(room);
  };

  const handleClose = () => setActiveZone(null);

  return (
    <Wrapper>
      <div className="mb-3">
        <ButtonDropdown
          isOpen={isDropdownOpen}
          toggle={() => setIsDropdownOpen((val) => !val)}
        >
          <DropdownToggle caret>Create...</DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setActiveZone("room")}>
              Room
            </DropdownItem>
            <DropdownItem onClick={() => setActiveZone("exit")}>
              Exit
            </DropdownItem>
            <DropdownItem onClick={() => setActiveZone("thing")}>
              Object
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
      {activeZone === "room" && (
        <CreateRoomForm onCreateRoom={handleCreateRoom} />
      )}
      {activeZone === "thing" && (
        <CreateThingForm onCreateThing={handleCreateThing} />
      )}
      {activeZone === "exit" && <CreateExitForm onClose={handleClose} />}
      {activeZone === "edit" && editingEntityUid && (
        <EditEntityForm
          uid={editingEntityUid}
          onClose={onCloseEditor}
          onChangeEntity={onChangeEntity}
        />
      )}
    </Wrapper>
  );
};

export default CreationZone;
