import React, { useContext } from "react";

export interface TInteractionMenuContext {
  openInteractionMenu: (x: number, y: number, uid: string) => void;
}

export const InteractionMenuContext = React.createContext<TInteractionMenuContext | null>(
  null
);

export const useInteractionMenu = () => {
  const ctx = useContext(InteractionMenuContext);
  if (!ctx) throw new Error("useInteractionMenu called without proper context");
  return ctx;
};
