// you might accuse me of re-inventing redux... my response... screw you dude

import { createContext, useContext } from "react";

export type GlobalMutationCallback = (data: any) => void;

export interface IGlobalMutationContext {
  publish: (topic: string, data: any) => void;
  subscribe: (topic: string, callback: GlobalMutationCallback) => void;
  unsubscribe: (topic: string, callback: GlobalMutationCallback) => void;
}

export const GlobalMutationContext =
  createContext<IGlobalMutationContext | null>(null);

export function useGlobalMutationContext() {
  const ctx = useContext(GlobalMutationContext);
  if (!ctx) throw new Error("GlobalMutationContext somehow not initialized");
  return ctx;
}
