interface FriendlyInit extends RequestInit {
  body?: any;
}

export function request(path: string, init: FriendlyInit = {}) {
  const initWithDefaults: RequestInit = {
    headers: { "Content-Type": "application/json", ...init.headers },
    credentials: "include",
    ...init,
  };
  if (initWithDefaults.body && typeof initWithDefaults === "object") {
    initWithDefaults.body = JSON.stringify(initWithDefaults.body);
  }
  return fetch(`${process.env.REACT_APP_API_HOST}${path}`, initWithDefaults);
}
