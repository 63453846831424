import React, { useEffect, useState } from "react";
import { Button, Input, ListGroup, ListGroupItem } from "reactstrap";
import styled from "styled-components";
import { EntityPicker } from "../../components/EntityPicker";
import { useSocket } from "../../socket";
import { EntityInfo } from "../Feed/hooks";

interface Props {
  entity: EntityInfo;
}

export function Properties({ entity }: Props) {
  const [isAdding, setIsAdding] = useState(false);
  const [newPropertyName, setNewPropertyName] = useState("");
  const socket = useSocket();

  const handleNewProperty = () => {
    setIsAdding(true);
  };
  const handleCreateProperty = () => {
    socket.emit(
      "editProperty",
      { entityUid: entity.uid, name: newPropertyName, value: "" },
      () => {
        setIsAdding(false);
      }
    );
  };

  return (
    <ListGroup>
      {Object.entries(entity.properties).map(([name, value]) => (
        <Property
          entityUid={entity.uid}
          name={name}
          value={value}
          key={name}
          onSaved={() => null}
        />
      ))}
      {isAdding && (
        <ListGroupItem className="d-flex align-items-center">
          Name:
          <Input
            value={newPropertyName}
            onChange={(e) => setNewPropertyName(e.target.value)}
            className="ml-2 flex-grow-1 mr-2"
          />
          <Button color="primary" onClick={handleCreateProperty}>
            Create
          </Button>
        </ListGroupItem>
      )}
      {!isAdding && (
        <ListGroupItem>
          <Button color="primary" onClick={handleNewProperty}>
            New property
          </Button>
        </ListGroupItem>
      )}
    </ListGroup>
  );
}

// KEKW
interface PropertyProps {
  name: string;
  value: any;
  entityUid: string;
  onSaved: () => void;
  isNew?: boolean;
}

type PropertyType = "string" | "number" | "boolean" | "entity";

function Property({ name, value, entityUid, onSaved, isNew }: PropertyProps) {
  const [editing, setEditing] = useState(!!isNew);
  const [valueType, setValueType] = useState<PropertyType>(
    typeof value === "object" && value.uid
      ? "entity"
      : (typeof value as PropertyType)
  );
  const [editValue, setEditValue] = useState<any>(value);
  const socket = useSocket();
  const isDeletable = name !== "name" && name !== "description";

  useEffect(() => {
    if (!editing) {
      setEditValue(value);
    }
  }, [value, editing]);

  const handleSave = () => {
    socket.emit("editProperty", { entityUid, name, value: editValue }, () => {
      setEditing(false);
      onSaved();
    });
  };

  const handleDelete = () => {
    socket.emit("deleteProperty", { entityUid, name }, () => {
      setEditing(false);
      onSaved();
    });
  };

  const handleChangeDataType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newType = event.target.value as PropertyType;
    setEditValue((editValue: any) => {
      if (newType === "number") {
        const parsed = Number(editValue);
        return Number.isNaN(parsed) ? 0 : parsed;
      } else if (newType === "string") {
        return String(editValue);
      } else if (newType === "boolean") {
        return true;
      } else if (newType === "entity") {
        return null;
      }
    });
    setValueType(newType);
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value as any;
    setEditValue((editValue: any) => {
      if (valueType === "number") {
        const parsed = Number(newValue);
        return Number.isNaN(parsed) ? editValue : parsed;
      } else if (valueType === "string") {
        return String(newValue);
      } else if (valueType === "boolean") {
        return Boolean(Number(newValue));
      } else if (valueType === "entity") {
        return { __entity_uid: newValue.uid };
      }
    });
  };

  return (
    <ListGroupItem key={name} className="d-flex align-items-center">
      {!editing && (
        <PropertyRow className="flex-grow-1 d-flex align-items-center">
          <div>{name}:</div>
          <PropertyValue className="ml-1 mr-1">
            {typeof value === "object"
              ? `${value.name} (#${value.friendlyId})`
              : String(value)}
          </PropertyValue>
          <Button
            outline
            color="info"
            size="sm"
            onClick={() => setEditing(true)}
          >
            Edit
          </Button>
        </PropertyRow>
      )}
      {editing && (
        <div className="flex-grow-1">
          <div>Editing {name}</div>
          <div className="d-flex align-items-center">
            <div className="mr-2" style={{ whiteSpace: "nowrap" }}>
              Data type:
            </div>
            <Input
              type="select"
              value={valueType}
              onChange={handleChangeDataType}
            >
              <option value="string">Text</option>
              <option value="number">Number</option>
              <option value="boolean">True/False</option>
              <option value="entity">Entity</option>
            </Input>
          </div>
          <div>Value:</div>
          {valueType === "boolean" && (
            <Input
              name="editValue"
              type="select"
              value={editValue ? 1 : 0}
              onChange={handleChangeValue}
            >
              <option value={1}>True</option>
              <option value={0}>False</option>
            </Input>
          )}
          {(valueType === "string" || valueType === "number") && (
            <Input
              name="editValue"
              onChange={handleChangeValue}
              value={editValue}
              className="mt-2 mb-2"
              type="textarea"
              style={{ width: "100%", resize: "vertical" }}
            />
          )}
          {valueType === "entity" && (
            <EntityPicker
              onSelect={(ent) =>
                setEditValue(ent ? { __entity_uid: ent.uid } : null)
              }
              initialSelection={value}
              className="mb-2"
            />
          )}
          <div className="d-flex">
            <Button color="primary" onClick={handleSave} className="mr-2">
              Save
            </Button>
            <Button color="secondary" onClick={() => setEditing(false)}>
              Cancel
            </Button>
            {isDeletable && (
              <Button color="danger" onClick={handleDelete} className="ml-auto">
                Delete
              </Button>
            )}
          </div>
        </div>
      )}
    </ListGroupItem>
  );
}

const PropertyRow = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;
const PropertyValue = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
`;
