import React, { useState } from "react";
import { useSocketListener } from "../../hooks";
import { useInteractionMenu } from "../InteractionMenuContext";
import { Interactable } from "./shared";

export function ActivePlayers() {
  const [players, setPlayers] = useState<{ uid: string; name: string }[]>([]);
  const { openInteractionMenu } = useInteractionMenu();
  useSocketListener("connectedPlayers", data => {
    setPlayers(data.players);
  });
  return (
    <div>
      <h5>Active Players</h5>
      {players.map(player => (
        <div key={player.uid}>
          <Interactable
            onClick={event =>
              openInteractionMenu(event.pageX, event.pageY, player.uid)
            }
          >
            {player.name}
          </Interactable>
        </div>
      ))}
    </div>
  );
}
