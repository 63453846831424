/* eslint import/no-webpack-loader-syntax: off */
import React, { useState } from "react";
import { Modal, Button } from "reactstrap";
import { useMarkdown } from "../../hooks";
import { useActivePlayerContext } from "../../player";

export function IntroModal({ onDismiss }: { onDismiss: () => void }) {
  const { loading, html } = useMarkdown("/content/Intro.md");
  if (loading) return null;
  return (
    <Modal isOpen size="md">
      <div className="p-3">
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <Button color="primary" onClick={onDismiss}>
          Sweet
        </Button>
      </div>
    </Modal>
  );
}

export function FirstTimeIntro() {
  const activePlayer = useActivePlayerContext();
  const [isOpen, setIsOpen] = useState(!activePlayer.lastConnection);
  if (!isOpen) return null;
  return <IntroModal onDismiss={() => setIsOpen(false)} />;
}
