import { useGlobalMutationContext } from "../globalMutation";
import { Interaction, EntityInfo, Target } from "../Main/Feed/hooks";
import { Player } from "../types";

export function isInteractionVisible(
  interaction: Interaction,
  entity: EntityInfo,
  player: Player
) {
  if (interaction.visibilityCondition === "inPlayerInventory") {
    return entity.locationUid === player.uid;
  } else if (interaction.visibilityCondition === "inPlayerLocation") {
    return entity.locationUid === player.locationUid;
  }
  return true;
}

const DM_INTERACTION_UID = "40bf1c76-44f1-46b4-865a-2a8646566d16";

export function getClientSideInteractions(entity: EntityInfo): Interaction[] {
  const interactions: Interaction[] = [];
  if (entity.kind === "player") {
    interactions.push({
      uid: DM_INTERACTION_UID,
      label: "send DM",
      inherited: false,
      validTargets: [],
      isClientSide: true,
    });
  }
  return interactions;
}

export function useClientSideInteractions() {
  const { publish } = useGlobalMutationContext();
  const byUid: Record<string, (entity: EntityInfo, target: Target) => void> = {
    [DM_INTERACTION_UID]: (entity) => {
      publish("focusChatDM", { entity });
    },
  };
  return (uid: string, entity: EntityInfo, target: Target) => {
    const int = byUid[uid];
    if (!int) {
      throw new Error(
        `useClientSideInteractions received unknown interaction uid: ${uid}`
      );
    }
    return int(entity, target);
  };
}
