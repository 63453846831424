import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faEye,
  faQuestionCircle,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { StatefulTooltip } from "../../components/StatefulTooltip";
import { useSocket } from "../../socket";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IntroModal } from "../Help/IntroModal";
import { request } from "../../httpClient";

const Container = styled.div`
  padding: 15px 25px 5px 25px;
  display: flex;
  gap: 25px;
`;

export function QuickActions() {
  const [showingIntro, setShowingIntro] = useState(false);
  const socket = useSocket();
  const handleLookAround = () => {
    socket.emit("lookAround");
  };
  const handleShowHelp = () => {
    setShowingIntro(true);
  };
  const handleOpenDocs = () => {
    window.open("/docs", "_blank");
  };
  const handleLogout = async () => {
    await request("/logout", { method: "POST" });
    window.location.href = "/";
  };
  return (
    <Container>
      <IconButton
        icon={faEye}
        onClick={handleLookAround}
        tooltip="Look around"
      />
      <IconButton
        icon={faQuestionCircle}
        onClick={handleShowHelp}
        tooltip="Help"
      />
      <IconButton
        icon={faBook}
        onClick={handleOpenDocs}
        tooltip="Creators' Handbook"
      />
      <div className="ml-auto">
        <IconButton
          icon={faRightFromBracket}
          onClick={handleLogout}
          tooltip="Log out"
        />
      </div>
      {showingIntro && <IntroModal onDismiss={() => setShowingIntro(false)} />}
    </Container>
  );
}

interface IconButtonProps {
  icon: IconProp;
  tooltip: string;
  onClick: () => void;
}

function IconButton({ icon, onClick, tooltip }: IconButtonProps) {
  return (
    <StatefulTooltip
      renderAnchor={(props) => (
        <FontAwesomeIcon
          {...props}
          icon={icon}
          onClick={onClick}
          size="lg"
          style={{ cursor: "pointer" }}
        />
      )}
      style={{ fontSize: "16px" }}
    >
      {tooltip}
    </StatefulTooltip>
  );
}
