import React from "react";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import "./index.css";
import Login from "./Login/Login";
import { Register } from "./Login/Register";
import { ForgotPassword } from "./Login/ForgotPassword";
import { MainWrapper } from "./Main/Main";
import { Authenticated } from "./player";
import { ResetPassword } from "./Login/ResetPassword";

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/forgotpassword">
          <ForgotPassword />
        </Route>
        <Route path="/resetpassword">
          <ResetPassword />
        </Route>
        <Authenticated>
          <Route path="/play">
            <MainWrapper />
          </Route>
        </Authenticated>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
