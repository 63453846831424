import React, { useState } from "react";
import CreationZone from "./CreationZone";
import { CreateRoomParams } from "./CreateRoomForm";
import { CreateThingParams } from "./CreateThingForm";
import styled from "styled-components";
import { useDragToResize } from "../../hooks";

interface Props {
  onCreateRoom: (room: CreateRoomParams) => void;
  onCreateThing: (thing: CreateThingParams) => void;
  editingEntityUid: string | null;
  onCloseEditor: () => void;
  onChangeEntity: (uid: string) => void;
}

const Container = styled.div`
  overflow-y: auto;
  max-height: 100vh;
  min-width: 250px;
  position: relative;
`;
const Handle = styled.div`
  width: 8px;
  height: 100%;
  position: absolute;
  cursor: col-resize;
  border-radius: 5px;
`;

export const ActionPanel = ({
  onCreateRoom,
  onCreateThing,
  editingEntityUid,
  onCloseEditor,
  onChangeEntity,
}: Props) => {
  const [width, setWidth] = useState(300);

  const handleMouseDown = useDragToResize((event) =>
    setWidth(document.body.clientWidth - event.clientX)
  );

  return (
    <Container style={{ width }}>
      <Handle onMouseDown={handleMouseDown} />
      <CreationZone
        onCreateRoom={onCreateRoom}
        onCreateThing={onCreateThing}
        editingEntityUid={editingEntityUid}
        onCloseEditor={onCloseEditor}
        onChangeEntity={onChangeEntity}
      />
    </Container>
  );
};
