import { useSocket } from "./socket";
import { useEffect, useState, useCallback } from "react";
import { EntityInfo } from "./Main/Feed/hooks";
import marked from "marked";

export const useFetcher = <T = any>(
  operation: string,
  params: any,
  options: { condition?: boolean } = {}
) => {
  const socket = useSocket();
  const [data, setData] = useState<T | undefined>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (typeof options.condition !== "undefined" && !options.condition) return;
    socket.emit(operation, params, (res: T) => {
      setData(res);
      setLoading(false);
    });
    setLoading(true);
  }, [operation, JSON.stringify(params)]);
  return { data, loading };
};

export const useSocketListener = <T = any>(
  event: string,
  callback: (data: T) => void
) => {
  const socket = useSocket();
  useEffect(() => {
    socket.on(event, callback);
    return () => void socket.off(event, callback);
  }, [event, callback]);
};

export const useDragToResize = (
  handleMouseMove: (event: MouseEvent) => void
) => {
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    if (dragging) {
      const handleMouseUp = () => {
        setDragging(false);
      };
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [dragging]);

  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
    setDragging(true);
  };

  return onMouseDown;
};

const markdownMemo = new Map<string, string>();

export function useMarkdown(file: string) {
  const [loading, setLoading] = useState(true);
  const [html, setHtml] = useState("");
  useEffect(() => {
    if (markdownMemo.has(file)) {
      setLoading(false);
      setHtml(markdownMemo.get(file)!);
      return;
    }

    (async () => {
      const res = await fetch(file);
      const text = await res.text();
      const output = marked(text);
      setHtml(output);
      setLoading(false);
      markdownMemo.set(file, output);
    })();
  }, []);
  return { loading, html };
}
