import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  EntityInfo,
  Interaction,
  Target,
  useDetectClickOutside,
  useEntityFetcher,
} from "./Feed/hooks";
import styled from "styled-components";
import { InteractionMenuContext } from "./InteractionMenuContext";
import { useSocket } from "../socket";
import {
  getClientSideInteractions,
  isInteractionVisible,
  useClientSideInteractions,
} from "../utils/interactions";
import { useActivePlayerContext } from "../player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
const FixedWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vh;
  top: 0;
`;

const Container = styled.div`
  position: absolute;
  border: 1px solid black;
  background: #656565;
  border-radius: 3px;
  width: max-content;
  min-width: 50px;
  max-width: 300px;
`;

const PositionedContainer = styled(Container)<{ x: number; y: number }>`
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
`;

const InteractionWrapper = styled.div<{ border?: boolean; active?: boolean }>`
  cursor: pointer;
  padding: 2px 5px;
  transition: background-color 0.1s linear;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ active }) => (active ? "#272727" : "transparent")};
  &:hover {
    background: #272727;
  }
  border-bottom: ${({ border }) => (border ? "1px solid gray" : "none")};
`;

const TargetMenu = styled(Container)`
  top: 0;
  left: 100%;
`;

const NoInteraction = styled.div`
  color: black;
  padding: 2px 5px;
`;

interface ManagerProps {
  children: React.ReactNode;
  onEdit: (uid: string) => void;
}

interface ActiveMenu {
  x: number;
  y: number;
  uid: string;
  entityInfo: EntityInfo;
}

export function InteractionMenuManager({ children, onEdit }: ManagerProps) {
  const [activeMenu, setActiveMenu] = useState<ActiveMenu | null>(null);
  const player = useActivePlayerContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const socket = useSocket();
  const { getEntity } = useEntityFetcher();
  const runClientSide = useClientSideInteractions();

  const openInteractionMenu = useCallback(
    async (x: number, y: number, uid: string) => {
      const entityInfo = await getEntity(uid);
      setActiveMenu({ x, y, uid, entityInfo });
    },
    []
  );
  const handleClose = () => {
    setActiveMenu(null);
  };
  const handleEdit = () => {
    onEdit(activeMenu!.uid);
    handleClose();
  };
  const handleSelect = (interaction: Interaction, target: Target) => {
    handleClose();
    if (interaction.isClientSide) {
      return runClientSide(interaction.uid, activeMenu!.entityInfo, target);
    }
    socket.emit("entity.interact", {
      entityUid: activeMenu!.uid,
      interactionUid: interaction.uid,
      target,
    });
  };
  useDetectClickOutside(ref, handleClose);

  let visibleInteractions: Interaction[] = [];
  if (activeMenu) {
    const normalInteractions = activeMenu?.entityInfo.interactions.filter((i) =>
      isInteractionVisible(i, activeMenu.entityInfo, player)
    );
    visibleInteractions = [
      ...normalInteractions,
      ...getClientSideInteractions(activeMenu.entityInfo),
    ];
  }

  return (
    <InteractionMenuContext.Provider value={{ openInteractionMenu }}>
      {children}
      {activeMenu && (
        <FixedWrapper>
          <PositionedContainer x={activeMenu.x} y={activeMenu.y} ref={ref}>
            {visibleInteractions.map((i) => (
              <InteractionItem
                entityUid={activeMenu.uid}
                interaction={i}
                key={i.uid}
                onActivate={(target: Target) => handleSelect(i, target)}
              />
            ))}
            <InteractionWrapper key="edit" onClick={handleEdit}>
              edit
            </InteractionWrapper>
          </PositionedContainer>
        </FixedWrapper>
      )}
    </InteractionMenuContext.Provider>
  );
}

interface InteractionProps {
  entityUid: string;
  interaction: EntityInfo["interactions"][number];
  onActivate: (target: Target) => void;
}

function InteractionItem({
  entityUid,
  interaction,
  onActivate,
}: InteractionProps) {
  const [isHovering, setIsHovering] = useState(false);
  const [targets, setTargets] = useState<Target[] | null>(null);
  const socket = useSocket();

  useEffect(() => {
    let didCancel = false;
    if (isHovering && interaction.validTargets.length > 0) {
      socket.emit(
        "getValidTargets",
        { interactionUid: interaction.uid, entityUid },
        (res: Target[]) => {
          if (!didCancel) {
            setTargets(res);
          }
        }
      );
      return () => {
        didCancel = true;
      };
    } else if (!isHovering && targets) {
      setTargets(null);
    }
  }, [socket, isHovering, interaction, entityUid]);

  const handleClickInteraction = () => {
    if (interaction.validTargets.length === 0) {
      onActivate(null);
    }
  };

  const handleClickTarget = (target: Target) => {
    onActivate(target);
  };

  return (
    <div
      className="position-relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <InteractionWrapper
        border
        active={isHovering}
        onClick={handleClickInteraction}
      >
        {interaction.label}
        {interaction.validTargets.length > 0 && (
          <FontAwesomeIcon icon={faAngleRight} className="ml-3" />
        )}
      </InteractionWrapper>
      {targets && (
        <TargetMenu>
          {targets.length > 0 &&
            targets.map((target, idx) => (
              <InteractionWrapper
                key={target?.uid}
                onClick={() => handleClickTarget(target)}
                border={idx !== targets.length - 1}
              >
                {target?.name ?? "nothing"}
              </InteractionWrapper>
            ))}
          {targets.length === 0 && (
            <NoInteraction>No targets available</NoInteraction>
          )}
        </TargetMenu>
      )}
    </div>
  );
}
