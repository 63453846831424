import * as React from "react";
import { Button, Modal } from "reactstrap";

interface Props {
  header?: string;
  onDelete: () => void;
  onCancel: () => void;
}

export const SimpleDeleteModal = ({
  header = "Really delete this?",
  onDelete,
  onCancel,
}: Props) => {
  return (
    <Modal isOpen>
      <div className="m-4">
        <h4 className="mb-3">{header}</h4>
        <p className="mb-3">You can't undo this.</p>
        <div className="d-flex">
          <Button color="danger" className="mr-3" onClick={onDelete}>
            Delete
          </Button>
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
