import { MonacoEditorProps } from "react-monaco-editor";
import { capitalize } from "../../utils/strings";

const defaultReverseValues: Record<string, string> = {
  n: "s",
  ne: "sw",
  e: "w",
  se: "nw",
  s: "n",
  sw: "ne",
  w: "e",
  nw: "se",
  north: "south",
  northeast: "southwest",
  east: "west",
  southeast: "northwest",
  south: "north",
  southwest: "northeast",
  west: "east",
  northwest: "southeast",
  up: "down",
  down: "up",
};
for (const [key, value] of Object.entries(defaultReverseValues)) {
  defaultReverseValues[capitalize(key)] = capitalize(value);
}

export function getReverse(dir: string): string | undefined {
  return defaultReverseValues[dir];
}

export const MONACO_OPTIONS: MonacoEditorProps["options"] = {
  minimap: { enabled: false },
  scrollBeyondLastLine: false,
};
